$(document).ready(initPage);

function initPage() {
  $('.header-search .search-input').focusin(function () {
    $('.header-search').addClass('is-active');
  });
  $('.header-search .search-input').focusout(function () {
    $('.header-search').removeClass('is-active');
  });

  function checkScroll() {
    if ($(window).scrollTop() > 0) {
      $('body').addClass('is-scrolled');
    } else {
      $('body').removeClass('is-scrolled');
    }
  }

  checkScroll();
  $(window).scroll(function () {
    checkScroll();
  });

  $('.header-toggler').click(function () {
    $('.header').toggleClass('is-menu');
  });

  $('.header-lang .lang-curr').click(function () {
    $('.header-lang').toggleClass('is-active');
  });

  // Home bnr
  setTimeout(function () {
    $('.homebnr-txt--1').removeClass('homebnr-anim');
  }, 1000);
  setTimeout(function () {
    $('.homebnr-txt--2').removeClass('homebnr-anim homebnr-anim-left');
  }, 2000);
  setTimeout(function () {
    $('.homebnr-txt--3').removeClass('homebnr-anim');
  }, 3000);
  setTimeout(function () {
    setInterval(function () {
      $('.homebnr-bg-i').animate(
        {
          opacity: '0.3',
        },
        {
          duration: 1500,
          queue: false,
        }
      );
      setTimeout(function () {
        $('.homebnr-bg-i').animate(
          {
            opacity: '1',
          },
          {
            duration: 1500,
            queue: false,
          }
        );
      }, 1500);
    }, 3000);
  }, 3000);

  //-----

  $('.tab[data-tab]').click(function () {
    if (!$(this).hasClass('is-active')) {
      $(this).addClass('is-active').siblings().removeClass('is-active');
      const activeTab = $(this).data('tab');
      $('.tabs-contents')
        .find("[data-tab-content='" + activeTab + "']")
        .addClass('is-active')
        .siblings()
        .removeClass('is-active');

      // $('.tabs-contents')
      //   .find("[data-tab-content='" + activeTab + "'] .swiper")
      //   .each(function () {
      //     const swiperInstance = $(this)[0].swiper;
      //     console.log('swiperInstance: ', swiperInstance);
      //     swiperInstance.update();
      //   });
    }
  });

  $('.tab[data-link] a').click(function () {
    const target = $($(this).attr('href'));
    let headerH = 0;
    if ($(window).width() > 1399) {
      headerH = 80;
    } else if ($(window).width() < 1024) {
      headerH = 46;
    } else {
      headerH = 64;
    }
    $('html, body').animate(
      {
        scrollTop: target.offset().top - headerH,
      },
      500
    );
  });

  $('.tab[data-filter]').click(function () {
    if (!$(this).hasClass('is-active')) {
      $(this).addClass('is-active').siblings().removeClass('is-active');
      const activeTab = $(this).data('filter');
      console.log(activeTab);
      if (activeTab == 'all') {
        $('.filter-contents .filter-content').addClass('is-active');
      } else {
        $('.filter-contents .filter-content').removeClass('is-active');
        $('.filter-contents')
          .find("[data-filter='" + activeTab + "']")
          .addClass('is-active');
      }
    }
  });

  $('.expand-item .item-toggler').click(function () {
    $(this).parents('.expand-item').toggleClass('is-active');
    $(this).parents('.expand-item').siblings().removeClass('is-active');
    const scrollToPosition = $(this).offset().top - $('.header').outerHeight();
    $('html, body').animate(
      {
        scrollTop: scrollToPosition,
      },
      500
    );
  });

  /*** Home ***/

  const awardsSwiper = new Swiper('.awards-swiper', {
    slidesPerView: '2',
    spaceBetween: 21,
    loop: true,
    autoplay: {
      delay: 5000,
      speed: 500,
    },
    grabCursor: true,
    pagination: {
      el: '.section-awards .swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      1024: {
        slidesPerView: 2,
      },
    },
  });

  const testimonialsSwiper = new Swiper('.testimonials-swiper', {
    slidesPerView: '1',
    spaceBetween: 21,
    loop: true,
    autoplay: {
      delay: 5000,
      speed: 500,
    },
    grabCursor: true,
    pagination: {
      el: '.section-testimonials .swiper-pagination',
      clickable: true,
    },
  });

  /*** Careers ***/
  const gallerySwiperContainer = document.querySelector(
    '.gallery-swiper-container'
  );
  const gallerySwiperSlides =
    gallerySwiperContainer.querySelectorAll('.swiper-slide');

  let totalSlideWidth = 0;
  for (let i = 0; i < gallerySwiperSlides.length - 1; i++) {
    totalSlideWidth += gallerySwiperSlides[i].offsetWidth;
  }

  console.log('loop: ', totalSlideWidth, gallerySwiperContainer.offsetWidth);

  var gallerySwiper = new Swiper('.gallery-swiper', {
    slidesPerView: 'auto',
    spaceBetween: 21,
    loop: totalSlideWidth > gallerySwiperContainer.offsetWidth,
    loopedSlides: 1,
    autoplay: {
      delay: 5000,
      speed: 500,
    },
    grabCursor: true,
    pagination: {
      el: '.gallery-swiper-container .swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      480: {
        slidesPerView: 'auto',
      },
    },
  });
  const teamSwiper = new Swiper('.team-1-swiper', {
    slidesPerView: '4',
    spaceBetween: 21,
    loop: true,
    autoplay: {
      delay: 5000,
      speed: 500,
    },
    grabCursor: true,
    pagination: {
      el: '.section-team .swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 4,
      },
    },
  });

  $('.jobs-item .item-toggler').click(function () {
    $(this).parents('.jobs-item').toggleClass('is-active');
  });

  /*** Single Service ***/
  const recognitionSwiper = new Swiper('.recognition-swiper', {
    slidesPerView: '4',
    spaceBetween: 21,
    loop: true,
    autoplay: {
      delay: 5000,
      speed: 500,
    },
    grabCursor: true,
    pagination: {
      el: '.recognition-swiper .swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 4,
      },
    },
  });

  /*** Newsroom ***/
  $('.posts-view-btn').click(function () {
    if (!$(this).hasClass('is-active')) {
      $(this).addClass('is-active').siblings().removeClass('is-active');
      const activeView = $(this).data('view');
      if (activeView == 'list') {
        $('.post-list').addClass('is-list');
      } else {
        $('.post-list').removeClass('is-list');
      }
      const target = $('.posts-view');
      let headerH = 0;
      if ($(window).width() > 1399) {
        headerH = 80;
      } else if ($(window).width() < 1024) {
        headerH = 46;
      } else {
        headerH = 64;
      }
      $('html, body').animate(
        {
          scrollTop: target.offset().top - headerH,
        },
        500
      );
    }
  });

  /*** About Us ***/

  // const aboutGallerySwiper = new Swiper('.about-gallery-swiper', {
  //   slidesPerView: 'auto',
  //   spaceBetween: 21,
  //   loop: true,
  //   loopedSlides: 2,
  //   grabCursor: true,
  //   pagination: {
  //     el: ".about-gallery-swiper-container .swiper-pagination",
  //     clickable: true
  //   },
  //   breakpoints: {
  //     0: {
  //       slidesPerView: 1
  //     },
  //     399: {
  //       slidesPerView: 'auto'
  //     }
  //   }
  // });

  const honorSwiper1 = new Swiper('.honor-swiper-1', {
    slidesPerView: '4',
    spaceBetween: 21,
    loop: true,
    autoplay: {
      delay: 5000,
      speed: 500,
    },
    grabCursor: true,
    pagination: {
      el: '.honor-swiper-1 .swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 4,
      },
    },
  });
  const honorSwiper2 = new Swiper('.honor-swiper-2', {
    slidesPerView: '4',
    spaceBetween: 21,
    loop: true,
    autoplay: {
      delay: 5000,
      speed: 500,
    },
    grabCursor: true,
    pagination: {
      el: '.honor-swiper-2 .swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 4,
      },
    },
  });
  const honorSwiper3 = new Swiper('.honor-swiper-3', {
    slidesPerView: '4',
    spaceBetween: 21,
    loop: true,
    autoplay: {
      delay: 5000,
      speed: 500,
    },
    grabCursor: true,
    // observer: true,
    // observeParents: true,
    pagination: {
      el: '.honor-swiper-3 .swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 4,
      },
    },
  });

  const proBonoSwiper = new Swiper('.pro-bono-swiper', {
    slidesPerView: '2',
    spaceBetween: 21,
    loop: true,
    autoplay: {
      delay: 5000,
      speed: 500,
    },
    grabCursor: true,
    pagination: {
      el: '.pro-bono-swiper .swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      1024: {
        slidesPerView: 2,
      },
    },
  });
}
